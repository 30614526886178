<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import QianYiHomePage from './views/QianYiHomePage.vue';

export default {
  components: { QianYiHomePage },
}
</script>

<style>
.imgPreview-item {
  display: block;
  width: auto;
  margin-top: 8px;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-left: 0px;
  cursor: pointer;
}

.imgPreview-modal>img {
  position: absolute;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  transform: translateZ(0px);
}

body .imgPreview-modal {
  touch-action: none;
  position: fixed;
  z-index: 2001;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  user-select: none;
}
</style>
