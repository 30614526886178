//导航栏入口

export const BasicFeatures = [
    [
        {
            routerTitle: '自定义工作台',
            routerDescribe: '自由配置工作台，让工作更高效',
            path: '/ProductFunction/CustomWorkbench',
            Imgpath: require('../assets/icon/Basic/NavBasic1.png')
        },
        {
            routerTitle: '日程',
            routerDescribe: '一键轻松创建日程，时间规划更方便更高效',
            path: '/ProductFunction/Schedule',
            Imgpath: require('../assets/icon/Basic/NavBasic2.png')
        },
    ],
    [
        {
            routerTitle: '待办工作台',
            routerDescribe: '一站式待办管理中心，更高效地规划和处理大小事',
            path: '/ProductFunction/PendingWorkbench',
            Imgpath: require('../assets/icon/Basic/NavBasic4.png')
        },
        {
            routerTitle: '我的关注',
            routerDescribe: '一键关注，确保事情件件落实',
            path: '/ProductFunction/MyConcern',
            Imgpath: require('../assets/icon/Basic/NavBasic5.png')
        },
    ],
    [
        {
            routerTitle: '考勤打卡',
            routerDescribe: '精准定位安全守护，考勤统计实时查看',
            path: '/ProductFunction/Attendance',
            Imgpath: require('../assets/icon/Basic/NavBasic6.png')
        },
        {
            routerTitle: '日志中心',
            routerDescribe: '高效工作汇报，激发组织活力',
            path: '/ProductFunction/LogCenter',
            Imgpath: require('../assets/icon/Basic/NavBasic3.png')
        },
        // {
        //     routerTitle: '视图编辑器',
        //     routerDescribe: '更加科学的视图编辑器，搜索、筛选更便捷',
        //     path: '/ProductFunction/ViewsEditors',
        //     Imgpath: require('../assets/icon/Basic/NavBasic7.png')
        // },
        // {
        //     routerTitle: '我的业绩',
        //     routerDescribe: '科学的业绩管理，激发员工积极性',
        //     path: '/ProductFunction/MyAchievement',
        //     Imgpath: require('../assets/icon/Basic/NavBasic8.png')
        // }
    ]
]
export const ProjectManage = [
    [
        {
            routerTitle: '项目清单',
            routerDescribe: '快速检索项目，了解项目情况',
            path: '/ProductFunction/ProjectList',
            Imgpath: require('../assets/icon/Manage/Manage1.png')
        },
        {
            routerTitle: '任务清单',
            routerDescribe: '检索及编制项目任务',
            path: '/ProductFunction/TaskList',
            Imgpath: require('../assets/icon/Manage/Manage2.png')
        },
        {
            routerTitle: '项目日志',
            routerDescribe: '快速查看项目团队成员每天计划及进展',
            path: '/ProductFunction/ProjectLog',
            Imgpath: require('../assets/icon/Manage/Manage3.png')
        },
    ],
    [
        {
            routerTitle: '进展记录',
            routerDescribe: '了解项目团队的进展情况，实时掌握项目进展',
            path: '/ProductFunction/ProgressRecorded',
            Imgpath: require('../assets/icon/Manage/Manage5.png')
        },
        {
            routerTitle: '项目计划',
            routerDescribe: '利用甘特图等各种表现方式快速检索及编制项目计划',
            path: '/ProductFunction/ProjectPlan',
            Imgpath: require('../assets/icon/Manage/Manage6.png')
        },
        {
            routerTitle: '工时管理',
            routerDescribe: '方便管理人员了解项目工时使用情况',
            path: '/ProductFunction/WorkTimeManage',
            Imgpath: require('../assets/icon/Manage/Manage7.png')
        },
        // {
        //     routerTitle: '项目账户',
        //     routerDescribe: '支持业绩申请、业绩流水检索等',
        //     path: '/ProductFunction/ProjectAccount',
        //     Imgpath: require('../assets/icon/Manage/Manage8.png')
        // }
    ],
    [
        // {
        //     routerTitle: '跟进记录',
        //     routerDescribe: '快速检索跟项目相关的各类跟进记录，例如客户沟通等',
        //     path: '/ProductFunction/FollowRecorded',
        //     Imgpath: require('../assets/icon/Manage/Manage9.png')
        // },
        // {
        //     routerTitle: '商务',
        //     routerDescribe: '随时了解项目商务情况，例如项目合同、联系人信息等',
        //     path: '/ProductFunction/Business',
        //     Imgpath: require('../assets/icon/Manage/Manage10.png')
        // },
        {
            routerTitle: '项目动态',
            routerDescribe: '快速检索项目的各类动态变化，例如项目变更等',
            path: '/ProductFunction/ProjectDynamic',
            Imgpath: require('../assets/icon/Manage/Manage11.png')
        },
        {
            routerTitle: '项目聊天群',
            routerDescribe: '以项目为维度建立聊天群，便于团队的沟通',
            path: '/ProductFunction/ProjectChatgroups',
            Imgpath: require('../assets/icon/Manage/Manage12.png')
        },
        {
            routerTitle: '项目组织',
            routerDescribe: '方便项目负责人对项目成员的管理',
            path: '/ProductFunction/ProjectOrganization',
            Imgpath: require('../assets/icon/Manage/Manage4.png')
        }
    ]
]
export const ResearchManage = [
    [
        {
            routerTitle: '研发清单',
            routerDescribe: '快速检索研发项目及产品',
            path: '/ProductFunction/RDList',
            Imgpath: require('../assets/icon/Manage/Manage1.png')
        },
        {
            routerTitle: '任务清单',
            routerDescribe: '检索及编制研发任务',
            path: '/ProductFunction/RDTaskList',
            Imgpath: require('../assets/icon/Manage/Manage2.png')
        },
        {
            routerTitle: '研发日志',
            routerDescribe: '快速查看研发团队成员每天计划及进展',
            path: '/ProductFunction/RDLog',
            Imgpath: require('../assets/icon/Manage/Manage3.png')
        },

    ],
    [
        {
            routerTitle: '进展记录',
            routerDescribe: '了解研发团队的进展情况，实时掌握研发进展',
            path: '/ProductFunction/RDProgressRecorded',
            Imgpath: require('../assets/icon/Manage/Manage5.png')
        },
        {
            routerTitle: '需求管理',
            routerDescribe: '研发版本追溯管理',
            path: '/ProductFunction/RDPlan',
            Imgpath: require('../assets/icon/Manage/Manage6.png')
        },
        {
            routerTitle: '工时管理',
            routerDescribe: '方便管理人员了解研发工时使用情况',
            path: '/ProductFunction/RDWorkTimeManage',
            Imgpath: require('../assets/icon/Manage/Manage7.png')
        },
        // {
        //     routerTitle: '项目账户',
        //     routerDescribe: '支持业绩申请、业绩流水检索等',
        //     path: '/ProductFunction/RDAccount',
        //     Imgpath: require('../assets/icon/Manage/Manage8.png')
        // }
    ],
    [
        // {
        //     routerTitle: '跟进记录',
        //     routerDescribe: '快速检索跟研发相关的各类跟进记录，例如客户沟通等',
        //     path: '/ProductFunction/RDFollowRecorded',
        //     Imgpath: require('../assets/icon/Manage/Manage9.png')
        // },
        {
            routerTitle: '商务',
            routerDescribe: '随时了解研发商务情况，例如研发合同、联系人信息等',
            path: '/ProductFunction/RDBusiness',
            Imgpath: require('../assets/icon/Manage/Manage10.png')
        },
        // {
        //     routerTitle: '研发动态',
        //     routerDescribe: '快速检索研发的各类动态变化，例如研发变更等',
        //     path: '/ProductFunction/RDDynamic',
        //     Imgpath: require('../assets/icon/Manage/Manage11.png')
        // },
        {
            routerTitle: '研发聊天群',
            routerDescribe: '以研发项目或研发产品为维度建立聊天群，便于团队的沟通',
            path: '/ProductFunction/RDChatgroups',
            Imgpath: require('../assets/icon/Manage/Manage13.png')
        },
        {
            routerTitle: '研发组织',
            routerDescribe: '方便项目负责人对研发成员的管理',
            path: '/ProductFunction/RDOrganization',
            Imgpath: require('../assets/icon/Manage/Manage4.png')
        }
    ]
]
export const CRMManage = [
    [
        {
            routerTitle: '活跃客户',
            routerDescribe: '快速检索活跃中的客户，方便跟进',
            path: '/ProductFunction/ActiveCustomers',
            Imgpath: require('../assets/icon/CRM/CRM1.png')
        },
        {
            routerTitle: '活跃商机',
            routerDescribe: '快速检索活跃中的商机，方便跟进',
            path: '/ProductFunction/ActivateBusiness',
            Imgpath: require('../assets/icon/CRM/CRM2.png')
        },
        {
            routerTitle: '正常应收',
            routerDescribe: '正常应收的跟进及回款处理',
            path: '/ProductFunction/NormalReceivable',
            Imgpath: require('../assets/icon/CRM/CRM3.png')
        },
        {
            routerTitle: '预签合同',
            routerDescribe: '合同编制、审批',
            path: '/ProductFunction/PresignedContracts',
            Imgpath: require('../assets/icon/CRM/CRM4.png')
        },
        {
            routerTitle: '应付管理',
            routerDescribe: '应付账款的跟进及管理',
            path: '/ProductFunction/PayableManagement',
            Imgpath: require('../assets/icon/CRM/CRM17.png')
        },
        // {
        //     routerTitle: '跟进管理',
        //     routerDescribe: '客户、商机、合同、回款跟进记录的综合管理',
        //     path: '/ProductFunction/CRMFollowRecorded',
        //     Imgpath: require('../assets/icon/CRM/CRM5.png')
        // },
        // {
        //     routerTitle: '全部商机',
        //     routerDescribe: '快速检索、管理所有商机',
        //     path: '/ProductFunction/AllBusiness',
        //     Imgpath: require('../assets/icon/CRM/CRM6.png')
        // },
    ],
    [
        {
            routerTitle: '客户公海',
            routerDescribe: '管理长期无法跟进或无负责人的客户',
            path: '/ProductFunction/CustomersGroup',
            Imgpath: require('../assets/icon/CRM/CRM7.png')
        },
        {
            routerTitle: '商机公海',
            routerDescribe: '管理长期无法跟进或关闭的商机',
            path: '/ProductFunction/BusinessGroup',
            Imgpath: require('../assets/icon/CRM/CRM8.png')
        },
        {
            routerTitle: '呆账管理',
            routerDescribe: '对归于呆账的应收进行管理',
            path: '/ProductFunction/DoubtfulDebt',
            Imgpath: require('../assets/icon/CRM/CRM9.png')
        },
        {
            routerTitle: '履约合同',
            routerDescribe: '针对履约中的合同进行管理',
            path: '/ProductFunction/PerformanceContracts',
            Imgpath: require('../assets/icon/CRM/CRM10.png')
        },
        {
            routerTitle: '采购合同',
            routerDescribe: '采购合同的编制、审批及管理',
            path: '/ProductFunction/PurchaseContracts',
            Imgpath: require('../assets/icon/CRM/CRM11.png')
        },
        // {
        //     routerTitle: '总应收',
        //     routerDescribe: '综合检索、管理所有应收',
        //     path: '/ProductFunction/PayableTotal',
        //     Imgpath: require('../assets/icon/CRM/CRM12.png')
        // },
    ],
    [
        {
            routerTitle: '全部客户',
            routerDescribe: '快速检索、管理所有客户',
            path: '/ProductFunction/AllCustomers',
            Imgpath: require('../assets/icon/CRM/CRM13.png')
        },
        {
            routerTitle: '成交商机',
            routerDescribe: '快速检索、管理已经成交的商机',
            path: '/ProductFunction/ClosingBusiness',
            Imgpath: require('../assets/icon/CRM/CRM14.png')
        },
        {
            routerTitle: '已收回款',
            routerDescribe: '快速检索、管理已收回款记录',
            path: '/ProductFunction/ClosingRecorded',
            Imgpath: require('../assets/icon/CRM/CRM15.png')
        },
        {
            routerTitle: '归档合同',
            routerDescribe: '快速检索已经归档合同',
            path: '/ProductFunction/ArchiveContracts',
            Imgpath: require('../assets/icon/CRM/CRM16.png')
        },
        // {
        //     routerTitle: '全部合同',
        //     routerDescribe: '综合检索、管理所有合同',
        //     path: '/ProductFunction/AllContracts',
        //     Imgpath: require('../assets/icon/CRM/CRM18.png')
        // },
    ]
]
export const OrganizationManage = [
    [
        {
            routerTitle: '组织配置',
            routerDescribe: '职能组织及非职能组织创建、删除',
            path: '/ProductFunction/OrganizationConfiguration',
            Imgpath: require('../assets/icon/Organization/Organization1.png')
        },
        // {
        //     routerTitle: '成员绩效',
        //     routerDescribe: '组织成员的绩效考核',
        //     path: '/ProductFunction/MemberPerformance',
        //     Imgpath: require('../assets/icon/Organization/Organization2.png')
        // },
        {
            routerTitle: '事项中心',
            routerDescribe: '快速检索组织成员所负责的事项',
            path: '/ProductFunction/MatterCenter',
            Imgpath: require('../assets/icon/Organization/Organization3.png')
        },
        // {
        //     routerTitle: '跟进记录',
        //     routerDescribe: '快速检索组织成员的跟进记录，例如客户联系',
        //     path: '/ProductFunction/OrFollowRecorded',
        //     Imgpath: require('../assets/icon/Organization/Organization4.png')
        // }
    ],
    [
        {
            routerTitle: '组织成员',
            routerDescribe: '组织成员的添加、移除等管理',
            path: '/ProductFunction/OrganizationMember',
            Imgpath: require('../assets/icon/Organization/Organization5.png')
        },
        {
            routerTitle: '成员工时',
            routerDescribe: '快速统计成员工时情况',
            path: '/ProductFunction/MemberWorkTime',
            Imgpath: require('../assets/icon/Organization/Organization6.png')
        },
        // {
        //     routerTitle: '进度计划',
        //     routerDescribe: '编制及检索组织成员的进度计划',
        //     path: '/ProductFunction/ScheduleProgress',
        //     Imgpath: require('../assets/icon/Organization/Organization7.png')
        // }
    ],
    [
        // {
        //     routerTitle: '组织账户',
        //     routerDescribe: '组织业绩、奖金等账户管理',
        //     path: '/ProductFunction/OrganizationAccount',
        //     Imgpath: require('../assets/icon/Organization/Organization8.png')
        // },
        {
            routerTitle: '成员日志',
            routerDescribe: '了解组织成员每天工作计划及进展',
            path: '/ProductFunction/MemberLog',
            Imgpath: require('../assets/icon/Organization/Organization9.png')
        },
        // {
        //     routerTitle: '进展记录',
        //     routerDescribe: '快速检索组织成员的工作进展',
        //     path: '/ProductFunction/OrProgressRecord',
        //     Imgpath: require('../assets/icon/Organization/Organization10.png')
        // },
    ]
]
export const OAApproval = [
    [
        {
            routerTitle: '发起审批',
            routerDescribe: '提供审批单的发起',
            path: '/ProductFunction/InitiateApproval',
            Imgpath: require('../assets/icon/OA/OA1.png')
        },
        {
            routerTitle: '已发起',
            routerDescribe: '快速检索我发起的审批记录',
            path: '/ProductFunction/Initiated',
            Imgpath: require('../assets/icon/OA/OA2.png')
        },
        {
            routerTitle: '审批单配置',
            routerDescribe: '各类审批单及分类的配置',
            path: '/ProductFunction/ApprovalOrderConfiguration',
            Imgpath: require('../assets/icon/OA/OA3.png')
        },
        {
            routerTitle: '自定义表单',
            routerDescribe: '采用低代码技术，支持所见即所得的表单编辑',
            path: '/ProductFunction/CustomForms',
            Imgpath: require('../assets/icon/OA/OA4.png')
        }
    ],
    [
        {
            routerTitle: '待处理',
            routerDescribe: '快速处理需要我处理的审批',
            path: '/ProductFunction/Pending',
            Imgpath: require('../assets/icon/OA/OA5.png')
        },
        {
            routerTitle: '抄送我的',
            routerDescribe: '快速检索抄送给我的审批记录',
            path: '/ProductFunction/CarbonCopyMine',
            Imgpath: require('../assets/icon/OA/OA6.png')
        },
        {
            routerTitle: '工作流配置',
            routerDescribe: '支持图形化工作流配置，并按需求进行分类',
            path: '/ProductFunction/WorkflowConfiguration',
            Imgpath: require('../assets/icon/OA/OA7.png')
        }
    ],
    [
        {
            routerTitle: '已处理',
            routerDescribe: '快速检索我已经处理的审批记录',
            path: '/ProductFunction/Pended',
            Imgpath: require('../assets/icon/OA/OA8.png')
        },
        {
            routerTitle: '审批代办',
            routerDescribe: '支持设置代办人，及查看代办历史',
            path: '/ProductFunction/ApprovalPending',
            Imgpath: require('../assets/icon/OA/OA9.png')
        },
        {
            routerTitle: '工作流引擎',
            routerDescribe: '实现对流程实例的解释及处理',
            path: '/ProductFunction/WorkflowEngine',
            Imgpath: require('../assets/icon/OA/OA10.png')
        },
    ]
]
export const ConferenceSystem = [
    [
        {
            routerTitle: '快速会议',
            routerDescribe: '提供随时随地开启在线会议',
            path: '/ProductFunction/FastConferenc',
            Imgpath: require('../assets/icon/Conference/Conference1.png')
        },
        // {
        //     routerTitle: '多人共享',
        //     routerDescribe: '支持多人同时共享，由会议主持人确定展示的共享',
        //     path: '/ProductFunction/SharePeople',
        //     Imgpath: require('../assets/icon/Conference/Conference2.png')
        // },
        // {
        //     routerTitle: '会议签到',
        //     routerDescribe: '一键发起签到，结果实时反馈，考勤状态一目了然',
        //     path: '/ProductFunction/MeetingSignIn',
        //     Imgpath: require('../assets/icon/Conference/Conference3.png')
        // }
        {
            routerTitle: '会议管控',
            routerDescribe: '提供会议主持人对会议的管理，例如禁音等',
            path: '/ProductFunction/MeetingControl',
            Imgpath: require('../assets/icon/Conference/Conference5.png')
        },
    ],
    [
        {
            routerTitle: '预约会议',
            routerDescribe: '支持在线会议的预约设置',
            path: '/ProductFunction/BookMeet',
            Imgpath: require('../assets/icon/Conference/Conference4.png')
        },
        // {
        //     routerTitle: '会议白板',
        //     routerDescribe: '提供激光笔、画笔、文本、图形等多种工具辅助演示',
        //     path: '/ProductFunction/MeetingWhiteboard',
        //     Imgpath: require('../assets/icon/Conference/Conference6.png')
        // }
    ],
    [
        {
            routerTitle: '节目单',
            routerDescribe: '提供手动添加或导入节目单',
            path: '/ProductFunction/Programe',
            Imgpath: require('../assets/icon/Conference/Conference7.png')
        },
        // {
        //     routerTitle: '会议录制',
        //     routerDescribe: '提供会议云录制及本地录制多种方式，重要会议内容一键存档',
        //     path: '/ProductFunction/MeetingRecording',
        //     Imgpath: require('../assets/icon/Conference/Conference8.png')
        // },
        // {
        //     routerTitle: '业务整合',
        //     routerDescribe: '通过与业务系统整合，会议过程可以实时了解项目、研发等情况',
        //     path: '/ProductFunction/BusinessIntegration',
        //     Imgpath: require('../assets/icon/Conference/Conference9.png')
        // },
    ]
]
export const ChatSystem = [
    [
        {
            routerTitle: '即时沟通',
            routerDescribe: '即时消息，便捷沟通',
            path: '/ProductFunction/InstantCommunication',
            Imgpath: require('../assets/icon/Chat/Chat1.png')
        },
        {
            routerTitle: '群组管理',
            routerDescribe: '对群成员、消息的管理',
            path: '/ProductFunction/GroupsManage',
            Imgpath: require('../assets/icon/Chat/Chat2.png')
        },
        {
            routerTitle: '聊天文件',
            routerDescribe: '聊天与文件协同一体化',
            path: '/ProductFunction/ChatFiles',
            Imgpath: require('../assets/icon/Chat/Chat3.png')
        },
        {
            routerTitle: '业务整合',
            routerDescribe: '即开即用的场景群，将应用和群深度融合',
            path: '/ProductFunction/ChatBusinessIntegration',
            Imgpath: require('../assets/icon/Chat/Chat4.png')
        },
    ],
    [
        // {
        //     routerTitle: '视频聊天',
        //     routerDescribe: '低延时，高清交流',
        //     path: '/ProductFunction/VideoChat',
        //     Imgpath: require('../assets/icon/Chat/Chat5.png')
        // },
        {
            routerTitle: '项目群组',
            routerDescribe: '关联项目，随时了解项目进展',
            path: '/ProductFunction/ProjectGroups',
            Imgpath: require('../assets/icon/Chat/Chat6.png')
        },
        {
            routerTitle: '聊天历史',
            routerDescribe: '信息多端同步，不丢失',
            path: '/ProductFunction/ChatHistory',
            Imgpath: require('../assets/icon/Chat/Chat7.png')
        },
        {
            routerTitle: '会议发起',
            routerDescribe: '远程协作，无缝交流',
            path: '/ProductFunction/MeetingInitiation',
            Imgpath: require('../assets/icon/Chat/Chat8.png')
        },
    ],
    [
        // {
        //     routerTitle: '语音聊天',
        //     routerDescribe: '语音聊天，沟通无障碍',
        //     path: '/ProductFunction/VoiceChat',
        //     Imgpath: require('../assets/icon/Chat/Chat9.png')
        // },
        {
            routerTitle: '组织群组',
            routerDescribe: '信息云端同步 数据只跟组织走',
            path: '/ProductFunction/OrganizeGroupChat',
            Imgpath: require('../assets/icon/Chat/Chat10.png')
        },
        {
            routerTitle: '通讯录',
            routerDescribe: '快速找人，数据全面安全保护',
            path: '/ProductFunction/Contacts',
            Imgpath: require('../assets/icon/Chat/Chat11.png')
        },
        {
            routerTitle: '消息通知',
            routerDescribe: '消息智能分类信息不遗漏',
            path: '/ProductFunction/MessageNotifications',
            Imgpath: require('../assets/icon/Chat/Chat12.png')
        },
    ]
]
export const EnterpriseCloudrives = [
    [
        {
            routerTitle: '应用空间',
            routerDescribe: '方便文件在各应用互转，协作更高效',
            path: '/ProductFunction/ApplicationSpace',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives1.png')
        },
        {
            routerTitle: '空间权限',
            routerDescribe: '按角色分配权限，安全更有保障',
            path: '/ProductFunction/SpacePermissions',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives2.png')
        },
        // {
        //     routerTitle: '微信文件备份',
        //     routerDescribe: '解放移动设备，文件更安全',
        //     path: '/ProductFunction/WXDocumentsFiling',
        //     Imgpath: require('../assets/icon/Cloudrives/Cloudrives3.png')
        // },
        {
            routerTitle: '版本管理',
            routerDescribe: '打造有序沉淀的企业智库，驱动企业创新增长',
            path: '/ProductFunction/VersionManagement',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives4.png')
        },
    ],
    [
        {
            routerTitle: '企业空间',
            routerDescribe: '统一沉淀和管理企业工作成果，员工可以随时查阅',
            path: '/ProductFunction/EnterpriseSpace',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives5.png')
        },
        // {
        //     routerTitle: '属性配置',
        //     routerDescribe: '按专业、场景贴上不同标签，查找更方便',
        //     path: '/ProductFunction/AttributeConfiguration',
        //     Imgpath: require('../assets/icon/Cloudrives/Cloudrives6.png')
        // },
        // {
        //     routerTitle: '文件关联',
        //     routerDescribe: '业务与文件协同一体化，协作更高效',
        //     path: '/ProductFunction/FileAssociations',
        //     Imgpath: require('../assets/icon/Cloudrives/Cloudrives7.png')
        // },
        {
            routerTitle: '文件权限',
            routerDescribe: '文件加密传输，支持只读、水印等多级权限设置，企业文件更安全',
            path: '/ProductFunction/FilePermissions',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives8.png')
        },
        {
            routerTitle: '文件分享',
            routerDescribe: '安全分享，随时了解分享访问历史',
            path: '/ProductFunction/FileSharing',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives11.png')
        }
    ],
    [
        {
            routerTitle: '个人空间',
            routerDescribe: '支持微信相册、微信文件的备份',
            path: '/ProductFunction/PersonalSpace',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives9.png')
        },
        {
            routerTitle: '上传下载',
            routerDescribe: '稳定、高速上传下载',
            path: '/ProductFunction/UploadDownload',
            Imgpath: require('../assets/icon/Cloudrives/Cloudrives10.png')
        },
    ]
]
export const ManageSystem = [
    [
        {
            routerTitle: '企业信息',
            routerDescribe: '支持搜索管控，企业信息更安全',
            path: '/ProductFunction/CompanyInformation',
            Imgpath: require('../assets/icon/System/System1.png')
        },
        {
            routerTitle: '日志中心',
            routerDescribe: '随时了解系统访问情况，企业安全更放心',
            path: '/ProductFunction/BackLogCenter',
            Imgpath: require('../assets/icon/System/System2.png')
        },
    ],
    [
        {
            routerTitle: '成员管理',
            routerDescribe: '人员信息导入统一管理，添加／邀请员工',
            path: '/ProductFunction/MemberManage',
            Imgpath: require('../assets/icon/System/System4.png')
        },
        {
            routerTitle: '应用管理',
            routerDescribe: '常用工具的整合',
            path: '/ProductFunction/BackApplicationManage',
            Imgpath: require('../assets/icon/System/System3.png')
        }
        // {
        //     routerTitle: '模板设置',
        //     routerDescribe: '丰富的自定义模板，满足不同场景的需求',
        //     path: '/ProductFunction/TemplateSettings',
        //     Imgpath: require('../assets/icon/System/System5.png')
        // },
        // {
        //     routerTitle: '存储管理',
        //     routerDescribe: '设置存储回收机制，存储管理更科学',
        //     path: '/ProductFunction/StorageManagement',
        //     Imgpath: require('../assets/icon/System/System6.png')
        // }
    ],
    [
        {
            routerTitle: '角色权限',
            routerDescribe: '合理权限分配，安全有保障',
            path: '/ProductFunction/RolePermissions',
            Imgpath: require('../assets/icon/System/System7.png')
        },
        // {
        //     routerTitle: '业务配置',
        //     routerDescribe: '业务配置化，满足不同业务的需求',
        //     path: '/ProductFunction/AttributeConfiguration',
        //     Imgpath: require('../assets/icon/System/System8.png')
        // }
    ]
]


export const Solution = [
    {
        routerTitle: '项目管理',
        path: '/ProjectControl',
        Imgpath: require('../assets/icon/Solution/SolutionIcon1.png')
    },
    {
        routerTitle: '研发管理',
        path: '/RDManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon2.png')
    },
    {
        routerTitle: '客户管理',
        path: '/CustomersManage',
        Imgpath: require('../assets/icon/Solution/SolutionIcon3.png')
    },
    {
        routerTitle: '商机管理',
        path: '/OpportunityManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon4.png')
    },
    {
        routerTitle: '合同管理',
        path: '/ContractManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon5.png')
    },
    {
        routerTitle: '应收应付',
        path: '/ReceivablesPayables',
        Imgpath: require('../assets/icon/Solution/SolutionIcon6.png')
    },
    {
        routerTitle: 'OA审批',
        path: '/OAApproval',
        Imgpath: require('../assets/icon/Solution/SolutionIcon7.png')
    },
    {
        routerTitle: '日程管理',
        path: '/ScheduleManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon8.png')
    },
    {
        routerTitle: '组织管理',
        path: '/OrganizatioManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon9.png')
    },
    {
        routerTitle: '会议管理',
        path: '/MeetingManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon10.png')
    },
    {
        routerTitle: '沟通管理',
        path: '/CommunicationManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon11.png')
    },
    {
        routerTitle: '资源管理',
        path: '/ResourceManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon12.png')
    },
    {
        routerTitle: '工时管理',
        path: '/TimeManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon13.png')
    },
    {
        routerTitle: '效能管理',
        path: '/PerformanceManagement',
        Imgpath: require('../assets/icon/Solution/SolutionIcon14.png')
    }
]
export const SolutionIndustry = [
    {
        routerTitle: '互联网',
        path: '/Internet',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground1.png')
    },
    {
        routerTitle: '专业服务',
        path: '/ProfessionalServices',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground2.png')
    },
    {
        routerTitle: '建筑工程',
        path: '/ArchitecturalDesign',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground3.png')
    },
    {
        routerTitle: '路桥工程',
        path: '/BridgeEngineering',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground4.png')
    },
    {
        routerTitle: '水利工程',
        path: '/WaterConservancy',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground5.png')
    },
    {
        routerTitle: '教育行业',
        path: '/EducationIndustry',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground6.png')
    },
    {
        routerTitle: '建筑设计',
        path: '/ArchitecturalDesign',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground7.png')
    },
    {
        routerTitle: '工程设计',
        path: '/EngineeringDesign',
        Imgpath: require('../assets/picture/Solutionbackground/Solutionbackground8.png')
    }
]