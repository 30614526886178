<template>
    <div class="RollTop">
        <div class="imgBox" @mouseenter="Hover = false" @mouseleave="Hover = true" @click ="Rolltop" v-if="showButton">
            <img src="../assets/picture/RollTop.png" v-if="Hover"  class="ImgTop">
            <img src="../assets/picture/RollTopBlue.png" @mouseleave="Hover = true" class="ImgHover">
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            Hover: true, showButton: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if(window.pageYOffset > 500) {
                this.showButton = window.pageYOffset
            }else {
                this.showButton = false
                this.Hover = true
            }
        },
        Rolltop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}

</script>

<style lang="less" scoped>
.RollTop {
    position: fixed;
    right: 24px;
    bottom: 24px;

    >.imgBox {
        border-radius: 50%;
        box-shadow: 0px 3px 6px 3px rgba(54, 94, 255, 0.08);
        width: 50px;
        height: 50px;
        cursor: pointer;
        >.ImgHover {
            z-index: 99;
            position: absolute;
        }
        >.ImgTop {
            z-index: 100;
            position: absolute;
        }
    }
}
</style>